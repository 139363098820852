// Mixins
export const MIXINS = {
  csrs: { url: 'initial/csr', item: 'csrs' },
  agents: { url: 'initial/agents', item: 'agents' },
  businessLines: { url: 'initial/line-of-businesses', item: 'businessLines' },
  companyClasses: { url: 'initial/company-classes', item: 'companyClasses' },
  companyTypes: { url: 'initial/company-types', item: 'companyTypes' },
  userClasses: { url: 'initial/user-classes', item: 'userClasses' },
  phoneTypes: { url: 'initial/phone-types', item: 'phoneTypes' },
  emailTypes: { url: 'initial/email-types', item: 'emailTypes' },
  socialTypes: { url: 'initial/social-types', item: 'socialTypes' },
  policyTypes: { url: 'initial/policy-types', item: 'policyTypes' },
  packageTypes: { url: 'initial/package-types', item: 'packageTypes' },
  terms: { url: 'initial/terms', item: 'terms' },
  financeOptions: { url: 'initial/finance-options', item: 'financeOptions' },
  billingTypes: { url: 'initial/billing-types', item: 'billingTypes' },
  premiumSents: { url: 'initial/premium-sents', item: 'premiumSents' },
  priorityTypes: { url: 'initial/priority-types', item: 'priorityTypes' },
  temperatureTypes: { url: 'initial/temperature-types', item: 'temperatureTypes' },
  archiveStatuses: { url: 'initial/status-types/3', item: 'archiveStatuses' },
  clientStatuses: { url: 'initial/status-types/2', item: 'clientStatuses' },
  leadStatuses: { url: 'initial/status-types/1', item: 'leadStatuses' },
  policyStatuses: { url: 'initial/policy-status-types', item: 'policyStatuses' },
  countries: { url: 'initial/countries', item: 'countries' },
  sources: { url: 'initial/agency-sources', item: 'sources' },
  agencies: { url: 'initial/agencies', item: 'agencies' },
  companies: { url: 'initial/companies', item: 'companies' },
  users: { url: 'initial/users', item: 'users' },
}

// User or Company for leads, clients, and agents
export const USER = '1'
export const COMPANY = '0'

// Policy Type
export const packagePolicyType = 2

// Term ID for annual
export const annualTermId = 1

// Temperature Type Ids
export const hot = 1
export const warm = 2
export const cool = 3
export const cold = 4

// Utilities
export const flatFilterFiles = (location, arr) => {
  return arr.filter(file => {
    const keep = file.location !== location
    if (keep && file.children && file.children.length) {
      file.children = flatFilterFiles(location, file.children)
    }
    return keep
   })
 }

export const flatFindFile = (location, arr) => {
  for (const file of arr) {
    if (file.location === location) {
      return file
    } else if (file.children && file.children.length) {
      const children = flatFindFile(location, file.children)
      if (children) {
        return children
      }
    }
  }

  return {
    location, children: [],
  }
}
