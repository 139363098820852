export const getCoverageFields = coverageTypes => {
  const commonFields = [
    { text: 'Prior Insurance', field: 'prior_insurance', icon: 'mdi-priority-low' },
    { text: 'Requested Plan', field: 'plan_requested', icon: 'mdi-priority-high' },
    { text: 'Requested Benefits', field: 'benefits_requested', icon: 'mdi-wallet-giftcard' },
    { text: 'Occupation', field: 'occupation', icon: 'mdi-account-hard-hat' },
  ]

  const blueFields = [
    { text: 'DUI/DWI', field: 'dui_dwi', icon: 'mdi-glass-mug-variant' },
    { text: 'Tobacco Use', field: 'tobacco_use', icon: 'mdi-smoking' },
    { text: 'Gender', field: 'gender', icon: 'mdi-gender-male-female' },
    { text: 'Height', field: 'height', icon: 'mdi-human-male-height' },
    { text: 'Weight', field: 'weight', icon: 'mdi-weight-pound' },
    { text: 'Birthdate', field: 'birthdate', icon: 'mdi-cake-variant' },
    { text: 'Major Conditions', field: 'major_conditions', icon: 'mdi-bell-alert' },
    { text: 'Other Conditions', field: 'other_conditions', icon: 'mdi-bell-plus-outline' },
    { text: 'Hospitalization', field: 'hospitalization', icon: 'mdi-hospital-building' },
    { text: 'Has Prescriptions', field: 'has_prescriptions', icon: 'mdi-pill' },
    { text: 'Medical Treatment', field: 'medical_treatment', icon: 'mdi-medical-bag' },
  ]

  const redFields = [
    { text: 'Time In Business', field: 'time_in_business', icon: 'mdi-briefcase-clock' },
    { text: 'Naics Primary', field: 'naics_primary', icon: 'mdi-numeric' },
    { text: 'Gross Payroll', field: 'gross_payroll', icon: 'mdi-currency-usd-circle' },
    { text: 'Payroll Amount 1', field: 'payroll_amount_1', icon: 'mdi-currency-usd-circle-outline' },
    { text: 'Payroll Class 1', field: 'payroll_class_1', icon: 'mdi-counter' },
    { text: 'Payroll Amount 2', field: 'payroll_amount_2', icon: 'mdi-currency-usd-circle-outline' },
    { text: 'Payroll Class 2', field: 'payroll_class_2', icon: 'mdi-counter' },
    { text: 'Payroll Amount 3', field: 'payroll_amount_3', icon: 'mdi-currency-usd-circle-outline' },
    { text: 'Payroll Class 3', field: 'payroll_class_3', icon: 'mdi-counter' },
    { text: 'Payroll Amount 4', field: 'payroll_amount_4', icon: 'mdi-currency-usd-circle-outline' },
    { text: 'Payroll Class 4', field: 'payroll_class_4', icon: 'mdi-counter' },
    { text: 'Payroll Amount 5', field: 'payroll_amount_5', icon: 'mdi-currency-usd-circle-outline' },
    { text: 'Payroll Class 5', field: 'payroll_class_5', icon: 'mdi-counter' },
    { text: 'Location Address 1', field: 'location_address_1', icon: 'mdi-map-marker' },
    { text: 'Location Owned 1', field: 'location_owned_1', icon: 'mdi-office-building' },
    { text: 'Location Address 2', field: 'location_address_2', icon: 'mdi-map-marker' },
    { text: 'Location Owned 2', field: 'location_owned_2', icon: 'mdi-office-building' },
    { text: 'Location Address 3', field: 'location_address_3', icon: 'mdi-map-marker' },
    { text: 'Location Owned 3', field: 'location_owned_3', icon: 'mdi-office-building' },
    { text: 'Location Address 4', field: 'location_address_4', icon: 'mdi-map-marker' },
    { text: 'Location Owned 4', field: 'location_owned_4', icon: 'mdi-office-building' },
    { text: 'Counties Performed', field: 'counties_performed', icon: 'mdi-map-search-outline' },
    { text: 'Has Independent Contractors', field: 'has_independent_contractors', icon: 'mdi-file-document-edit-outline' },
  ]

  const returnValue = coverageTypes.map(type => {
    // type === 1 means blue fields in db
    if (type.type === 1) {
      return { name: type.name, fields: [...commonFields, ...blueFields] }
    } else {
      return { name: type.name, fields: [...commonFields, ...redFields] }
    }
  })

  return returnValue
}

export const today = new Date().toISOString().substr(0, 10)
