<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-weather-sunny"
          title="Hot Leads"
          :value="leads.hotLeads"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
          :to="`/leads?temperature=${hot}`"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-white-balance-sunny"
          title="Warm Leads"
          :value="leads.warmLeads"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
          :to="`/leads?temperature=${warm}`"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="warning"
          icon="mdi-cloud-outline"
          title="Cool Leads"
          :value="leads.coolLeads"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
          :to="`/leads?temperature=${cool}`"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-snowflake"
          title="Cold Leads"
          :value="leads.coldLeads"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
          :to="`/leads?temperature=${cold}`"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-shield-account"
          title="Total Clients"
          :value="leads.allClients"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
          to="/clients"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-shield-sync"
          title="Potential Renewal"
          :value="leads.potentialRenewal"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
          to="/policies?renewal=true"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          icon="mdi-chart-gantt"
          inline
          title="Total Premiums (× 1000 $)"
        >
          <!-- <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="premiumDates"
                prepend-icon="mdi-calendar"
                label="Choose Date"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="premiumDates"
              no-title
              color="primary"
              @input="getPremiums"
            />
          </v-menu> -->
          <v-progress-linear
            v-if="loading || fetchingPremiums"
            indeterminate
          />
          <apexcharts
            :options="{...barChartOptions}"
            :series="premiumSeries"
          />
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          icon="mdi-chart-pie"
          inline
          title="Active Policies"
        >
          <!-- <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="policyDates"
                prepend-icon="mdi-calendar"
                label="Choose Date"
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="policyDates"
              no-title
              color="primary"
              @input="getPolicies"
            />
          </v-menu> -->
          <v-progress-linear
            v-if="loading || fetchingPolicies"
            indeterminate
          />
          <!-- <apexcharts
            v-if="policySeries.length"
            :options="{...pieChartOptions}"
            :series="policySeries"
          /> -->
          <apexcharts
            :options="{...pieChartOptions}"
            :series="policySeries"
          />
        </base-material-card>
      </v-col>
    </v-row>

    <clients
      title="Recent Clients"
      url="customer/clients?recent=1"
    />

    <leads
      title="Recent Leads"
      url="customer/leads?recent=1"
    />
  </v-container>
</template>

<script>
  import axios from 'axios'
  import VueApexCharts from 'vue-apexcharts'
  import { mapActions } from 'vuex'
  import { today } from '@/utils'
  import { hot, warm, cool, cold } from '@/constants'

  export default {
    name: 'Dashboard',
    components: {
      apexcharts: VueApexCharts,
      Leads: () => import('./customers/Leads'),
      Clients: () => import('./customers/Clients'),
    },
    data: () => ({
      loading: false,
      menu: false,
      menu1: false,
      leads: {},
      barChartOptions: {
        chart: {
          type: 'bar',
          height: 300,
          toolbar: { show: false },
        },
        noData: {
          text: 'Loading...',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ['New Business', 'Renewal'],
        },
        colors: ['#732725', '#174446', '#299330', '#206D71', '#27A2A8', '#FF953A'],
      },
      pieChartOptions: {
        chart: {
          type: 'pie',
          height: 348,
        },
        labels: ['New Business', 'Renewal'],
        noData: {
          text: 'Loading...',
        },
        legend: { position: 'bottom' },
        colors: ['#732725', '#174446', '#299330', '#206D71', '#27A2A8', '#FF953A'],
      },
      premiumDates: today,
      premiumSeries: [{ name: 'Premiums', data: [0, 0] }],
      policyDates: today,
      policySeries: [],
      fetchingPremiums: false,
      fetchingPolicies: false,
      hot,
      cold,
      cool,
      warm,
    }),

    mounted () {
      this.getData()
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getData () {
        this.loading = true
        try {
          const response = await axios.get('dashboard')
          const { clients, leads, premium, policies } = response.data
          this.leads.hotLeads = leads.hot_leads.toString()
          this.leads.warmLeads = leads.warm_leads.toString()
          this.leads.coolLeads = leads.cool_leads.toString()
          this.leads.coldLeads = leads.cold_leads.toString()
          this.leads.allClients = clients.total_clients.toString()
          this.leads.potentialRenewal = clients.potential_renewal.toString()
          this.premiumSeries = [{ name: 'Premiums', data: [premium.new_business_total_premium / 1000, premium.renewal_total_premium / 1000] }]
          this.policySeries = policies.data
          this.pieChartOptions.labels = policies.label
        } catch (err) {
          this.showSnackBar({ text: err, color: 'error' })
        }
        this.loading = false
      },

      async getPremiums () {
        this.fetchingPremiums = true
        this.menu = false
        try {
          const response = await axios.post('premium', { expiration_date: this.premiumDates })
          this.premiumSeries = [{ name: 'Premiums', data: [response.data.all_new_premium / 1000, response.data.all_renewal_premium / 1000] }]
        } catch (err) {
          this.showSnackBar({ text: err, color: 'error' })
        }
        this.fetchingPremiums = false
      },

      async getPolicies () {
        this.fetchingPolicies = true
        this.menu1 = false
        try {
          const response = await axios.post('renewal-count', { expiration_date: this.policyDates })
          this.policySeries = [response.data.all_new_count, response.data.all_renewal_count]
        } catch (err) {
          this.showSnackBar({ text: err, color: 'error' })
        }
        this.fetchingPolicies = false
      },
    },
  }
</script>
